'use client'
import Link from 'next/link'
import Card from 'antd/es/card/Card'
import Input from 'antd/es/input/Input'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { Button, Col, Form, Layout, Row, Spin, Tag, message, Grid } from 'antd'
import { useGetTrackingMutation } from '@/lib/features/online-tracking/onlineTrackingApiSlice'
import styles from './page.module.css'
import { LoadingOutlined } from '@ant-design/icons'
import { useState } from 'react'
import Image, { getImageProps } from 'next/image'
import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const CaptchaForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [captchaToken, setCaptchaToken] = useState(''); // Para almacenar el token de reCAPTCHA
  const [isVerified, setIsVerified] = useState(false); // Para controlar si el reCAPTCHA es válido
  const [isSubmitting, setIsSubmitting] = useState(false); // Para manejar el estado de envío
  const [form] = Form.useForm()
  const [getTracking, { isLoading }] = useGetTrackingMutation()
  const [currentTracking, setCurrentTracking] = useState<CurrentTracking[]>([])
  const [lastState, setLastState] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [messageApi, contextHolder] = message.useMessage()
  const onFinish = async (values: any) => {
    form.setFields([{ name: 'order_number', errors: [] }])
    try {
      const resp = await getTracking(values)

      if ('error' in resp) {
        const errMessage = 'Solo debe ingresar números'
        form.setFields([
          {
            name: 'order_number',
            errors: [errMessage],
          },
        ])
        setCurrentTracking([])
        setLastState('')
      } else if ('data' in resp) {
        setOrderNumber(values.order_number)
        const formattedTracking = formatTracking(resp.data)
        setCurrentTracking(formattedTracking)
        setLastState(formattedTracking[0].state)
        form.resetFields()
      }
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: '¡No existen registros!',
      })
    }
  }

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('reCAPTCHA aún no está listo');
        return;
      }

      // Ejecutar reCAPTCHA y obtener el token
      const token = await executeRecaptcha('submit_form');
      console.log('Token de reCAPTCHA:', token);
      setCaptchaToken(token);
      setIsVerified(true);
      console.log('reCAPTCHA verificado correctamente.');
      
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true); // Mostrar que se está procesando el envío

    if (!captchaToken) {
      console.log('No se ha generado un token de reCAPTCHA válido.');
      return;
    }

    // Aquí puedes hacer la validación con el backend
    const response = await fetch('/api/verify-recaptcha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: captchaToken }),
    });

    const data = await response.json();
    
    if (data.success) {
      setIsVerified(true);
      console.log('reCAPTCHA verificado correctamente.');
      // Aquí puedes continuar con la lógica para enviar el formulario al servidor
    } else {
      console.log('La verificación de reCAPTCHA falló.');
    }

    setIsSubmitting(false); // Finalizar el estado de envío
  };

  return (
    <div>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 10, offset: 4 }}>
          <Card style={cardStyle}>
            <Row style={{ marginBottom: '34px' }}>
              <Col lg={{ span: 18 }} xs={{ span: 24 }}>
                <span className={styles.cardTextStyle}>
                  Buscar N° de pedido
                </span>
              </Col>
            </Row>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{ country_code: 56 }}
            >
              <Form.Item name="country_code" style={{ display: 'none' }}>
                <Input type="hidden" />
              </Form.Item>
              <Row justify={'start'} gutter={[20, 24]}>
                <Col xl={{ span: 18 }} xs={{ span: 24 }}>
                  <Form.Item name="order_number">
                    <Input
                      size="large"
                      placeholder="Ingresa tu n° de seguimiento"
                    />
                  </Form.Item>
                </Col>
                <Col xl={{ span: 5, offset: 0 }} xs={{ offset: 6 }}>
                  <Button
                    type="primary"
                    className="btn-primary"
                    style={{ width: '125px', height: '38px' }}
                    htmlType="submit"
                    disabled={!isVerified || isSubmitting}
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar'}
                  </Button>
                  {!isVerified && <p>Por favor, espera mientras verificamos que no eres un robot.</p>}
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      {!isLoading ? (
            currentTracking.length > 0 ? (
              <>
                <Row justify={'start'}>
                  <Col
                    xl={{ span: 16, offset: 4 }}
                    xs={{ span: 22, offset: 2 }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '28px',
                      }}
                    >
                      N° de pedido {orderNumber}
                    </span>
                    <Tag color="#30BA00">{lastState}</Tag>
                  </Col>
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    xl={{ span: 16, offset: 4 }}
                    style={{ marginTop: '30px' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        borderBottom: '1px solid #D9D9D9',
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '157px', marginTop: '50px' }}>
                  <Col
                    xl={{ span: 24, offset: 4 }}
                    xs={{ span: 20, offset: 4 }}
                  >
                    {currentTracking.map((tracking: CurrentTracking, index) => (
                      <Row key={index}>
                        <Col xl={{ span: 2 }} xs={{ span: 7 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              textAlign: 'end',
                            }}
                          >
                            <span
                              className={
                                index === 0
                                  ? styles.dateTrackingFirst
                                  : styles.dateTrackingLast
                              }
                            >
                              {tracking.date}
                            </span>
                            <span
                              style={{
                                color: '#999999',
                                fontSize: '16px',
                                lineHeight: '22px',
                              }}
                            >
                              {tracking.time}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xl={{ span: 1 }}
                          xs={{ span: 3 }}
                          style={{ textAlign: 'center' }}
                        >
                          <Image
                            src={
                              index === 0
                                ? './tracking-point.svg'
                                : './tracking-point-grey.svg'
                            }
                            width={12}
                            height={12}
                            alt="point"
                          />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              style={{
                                height: '100px',
                                width: '1px',
                                border: '1px solid #00000008',
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          xl={{ span: 3 }}
                          md={{ span: 10 }}
                          xs={{ span: 10 }}
                        >
                          <span
                            style={{
                              fontWeight: '500',
                            }}
                            className={
                              index === 0
                                ? styles.dateTrackingFirst
                                : styles.dateTrackingLast
                            }
                          >
                            {tracking.state}
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </>
            ) : (
              <Row justify={'center'} style={{ marginBottom: '157px' }}>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '24px',
                    }}
                  >
                    <Image
                      src="/package.svg"
                      alt="sin información"
                      width={153}
                      height={153}
                    />
                    <p className={styles.searchTextStyle}>No hay información</p>
                  </div>
                </Col>
              </Row>
            )
          ) : (
            <Row justify={'center'} style={{ marginBottom: '157px' }}>
              <Col>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 104, color: '#0033A0' }}
                      spin
                    />
                  }
                />
              </Col>
            </Row>
          )}
    </div>
  );
};

const { useBreakpoint } = Grid

const cardStyle: React.CSSProperties = {
  borderRadius: '10px',
  background: '#FFF',
  boxShadow: '0px 0px 4px 2px rgba(0,0,0, 0.33)',
  padding: '17px',
  position: 'relative',
  bottom: '110px',
}

interface Tracking {
  date: string
  state: string
}
interface CurrentTracking {
  date: string
  time: string
  state: string
}

const formatTracking = (tracking: any) => {
  const currentsTracking = tracking.map((item: Tracking) => {
    const currentTracking: CurrentTracking = {
      date: item.date.slice(0, 10),
      time: item.date.slice(11, 19),
      state: item.state,
    }
    return currentTracking
  })

  return currentsTracking
}

function getBackgroundImage(srcSet = '') {
  const imageSet = srcSet
    .split(', ')
    .map((str) => {
      const [url, dpi] = str.split(' ')
      return `url("${url}") ${dpi}`
    })
    .join(', ')
  return `image-set(${imageSet})`
}

export default function OnlineTrackingPage() {
  const screens = useBreakpoint()
  const currentYear = new Date().getFullYear()


  const [currentTracking, setCurrentTracking] = useState<CurrentTracking[]>([])
  const [messageApi, contextHolder] = message.useMessage()
  const [orderNumber, setOrderNumber] = useState('')
  const [lastState, setLastState] = useState('')
  const [getTracking, { isLoading }] = useGetTrackingMutation()

  const {
    props: { srcSet },
  } = getImageProps({
    alt: '',
    width: 1700,
    height: 400,
    src: '/public-home.png',
  })
  const backgroundImage = getBackgroundImage(srcSet)
  const Bannerstyle = {
    backgroundImage,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '400px',
  }

  return (
    <>
      {contextHolder}
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ background: '#FFF', height: '100%', padding: '0' }}>
          <Row align={'middle'} style={{ height: '104px' }}>
            <Col
              lg={{ offset: 4 }}
              xs={{ offset: 1 }}
              md={{ offset: 4 }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '40px',
              }}
            >
              <Image
                src="/intercarry-logo.svg"
                alt="optimal logo"
                width={211}
                height={60}
                priority={false}
              />
              <span className={styles.navTextStyle}>
                OPL, rastreo de pedidos
              </span>
            </Col>

            <Col lg={{ offset: 7 }} xl={{ offset: 8 }}>
              <Link key="login" href="/auth/login" className={styles.navStyle}>
                <Button className={`${styles.buttonNavStyle} btn-tertiary`}>
                  Iniciar sesión
                </Button>
              </Link>
            </Col>
          </Row>
        </Header>
        <Content style={{ background: '#FFFFFF' }}>
{/*           {screens.xs ? (
            <Row className={styles.homeStyle} >
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 7, offset: 4 }}>
                <div className={styles.titleContainerStyle}>
                  <span className={styles.titleStyle}>
                    Controla tus pedidos en un clic.
                  </span>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={Bannerstyle}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 7, offset: 4 }}>
                <div className={styles.titleContainerStyle}>
                  <span className={styles.titleStyle}>
                    Controla tus pedidos en un clic.
                  </span>
                </div>
              </Col>
            </Row>
          )} */}
          <Row className={styles.homeStyle}>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 7, offset: 4 }}>
              <div className={styles.titleContainerStyle}>
                <span className={styles.titleStyle}>
                  Controla tus pedidos en un clic.
                </span>
              </div>
            </Col>
          </Row>
          <GoogleReCaptchaProvider reCaptchaKey="6LcLgj4qAAAAANkf327F4JwtvBDIxZ3oMzCx4zZr">
            <CaptchaForm/>
          </GoogleReCaptchaProvider>
            
          
        </Content>
        <Footer className={styles.footerStyle}>
          <Row>
            <Col lg={12}>
              <Link href="https://theoptimalpartner.com">
                <p className={styles.footerTextStyle}>
                  © {currentYear} The Optimal
                </p>
              </Link>
            </Col>
            <Col lg={12}>
              <p className={styles.footerTextStyle}>¿Necesitas ayuda?</p>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </>
  )
}

import apiSlice from '@/lib/api/apiSlice'

export const onlineTrackingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTracking: builder.mutation({
      query: (params) => ({
        url: '/tracking_order',
        method: 'POST',
        body: params,
      }),
    }),
  }),
})

export const { useGetTrackingMutation } = onlineTrackingApiSlice
